#root {
  padding: .1px;
}

main {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  padding: 1rem;
  min-height: 100vh;
  min-height: 100dvh;
}

.title {
  margin: 0;
}

@media (max-width: 768px) {
  .title {
    font-size: 24px;
  }
}

.btn {  
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

.btn:hover {
  background-color: #2681F2;
}
